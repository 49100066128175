import messages from 'ra-language-english';

export default {
  ...messages,
  ra: {
    ...messages.ra,
    auth: {
      ...messages.ra.auth,
      username_or_email: 'Username or email',
      fullname: 'Full name',
      email: 'Email',
      invitation_code: 'Invitation code'
    },
    message: {
      ...messages.ra.message,
      delete_title: 'Delete %{name}?'
    },
    validation: {
      ...messages.ra.validation,
      unique: 'This is already taken.'
    }
  },
  action: {
    back: 'Back',
    cancel: 'Cancel',
    clone: 'Clone',
    cloneWithParams: 'Clone %{name}',
    change: 'Change',
    create_new: 'Create new',
    done: 'Done',
    download: 'Download',
    generate: 'Generate',
    go_to_dashboard: 'Go to dashboard',
    help: 'Help',
    export: 'Export',
    exit_administration: 'Exit administration',
    import: 'Import',
    next: 'Next',
    ok: 'Ok',
    previous: 'Previous',
    resend: 'Resend',
    see_all: 'See all',
    send: 'Send',
    show_more: 'Show more',
    show_less: 'Show less',
    submit: 'Submit',
    try_again: 'Try again',
    undo_action: 'Undo %{action}',
    reset: 'Reset',
    overwrite: 'Overwrite'
  },
  components: {
    menu: {
      groups: {
        environment_variables: 'Environment variables'
      }
    },
    costs_summary: {
      title: 'Monthly costs',
      total: 'Sum'
    },
    envVar: {
      fields: {
        isSecured: 'Is secured',
        key: 'Key',
        type: 'Type',
        value: 'Value'
      },
      validation: {
        invalid_key:
          'Only uppercase letters, numbers and underscores are allowed.'
      }
    },
    git: {
      fields: {
        encoding: 'Encoding',
        gitType: 'I have my own git repository',
        gitType_internal: 'Internal git repository',
        credentialType: 'Credential Type',
        password: 'Password or API token',
        privateKey: 'Private key',
        publicKey: 'Public key',
        publicKeyHelper:
          'We recommend using %{name} encoding unless you are using legacy systems.',
        url: 'Repository URL',
        username: 'Username',
        __confirm_write_access:
          'I confirm that my credentials grant write access to the repository.'
      },
      validation: {
        invalid_http_url: 'Please enter a valid repository http url',
        invalid_ssh_url: 'Please enter a valid repository ssh url',
        connection_is_not_tested: `You can't proceed without successful connection test.`,
        connection_test_failed: `Couldn't connect to git repository`
      },
      action: {
        generate_key: 'Generate key',
        test_connection: 'Test connection'
      },
      message: {
        connection_tested: 'Connection tested successfully',
        key_generation_failed: 'Failed to generate key',
        github_password_unsupported:
          'GitHub removed support for password authentication. Please use an API token instead.',
        public_repo_credentials_message: 'For public repositories, you can leave the credential fields empty.',
      }
    },
    environmentProgressBar: {
      label: {
        creating: 'Creating',
        updating: 'Updating'
      },
      messages: {
        ongoing_progresses:
          '%{smart_count} ongoing process |||| %{smart_count} ongoing processes',
        steps_left:
          '%{step} of %{smart_count} step |||| %{step} of %{smart_count} steps',
        steps_left_short: '%{step}/%{smart_count} |||| %{step}/%{smart_count}'
      }
    },
    switchTeam: {
      access_title: 'Change organisation and team',
      dialog_title: 'Select organisation and team'
    },
    usage_summary: {
      title: 'Monthly usage'
    },
    userMenu: {
      switch_theme: 'Turn on %{theme} mode'
    }
  },
  message: {},
  misc: {
    admin: 'System Admin',
    affected: 'Affected %{name}',
    all: 'All',
    and: 'and',
    hide_advanced: 'Hide advanced',
    id_est: 'i.e.',
    loading: 'Loading%{dots}',
    name: 'Name',
    recent: 'Recent %{name}',
    selected: 'Selected %{name}',
    show_advanced: 'Show advanced',
    sum: 'SUM',
    copy_to_clipboard: 'Copy',
    copied_to_clipboard: 'Copied to clipboard',
    recommended: 'Recommended',
    default: 'Default',
    none: 'None',
    gt_days: '> %{days} days',
    gt_months: '> %{months} months'
  },
  notification: {
    password_error:
      'Internal server error - may your current password is incorrect 😉',
    rejected:
      'You have no permission to see the requested page. Redirected to dashboard.'
  },
  page: {
    account: 'Account',
    billing: 'Billing',
    limits: 'Limits',
    profile: 'Profile Settings',
    reports: 'Reports',
    settings: 'Settings'
  },
  pages: {
    welcome: {
      welcome: '👏 Welcome',
      subtitle: "We are glad you're here. Let's get started!",
      subtitle2: 'Choose your stack and create your first project. 👇',
      subitle_from_scratch: 'or start',
      button_from_scratch: 'from scratch 🫰'
    },
    billing: {
      invoices: {
        empty: 'There is no any invoice yet',
        fields: {
          issuedAt: 'Issued at',
          remaining: 'Remaining amount',
          status: 'Status',
          total: 'Total amount'
        }
      },
      method: {
        empty: 'There is no any payment method registered',
        action: {
          subscribe: 'Subscribe for future payments'
        },
        fields: {
          expires: 'Expires',
          holder: 'Cardholder name',
          card_number: 'Card number',
          billing_details: 'Billing details',
          billing_address: 'Billing address',
          details: 'Card details'
        },
        notification: {
          successful_delete: 'Payment method has been successfully deleted.'
        }
      },
      requests: {
        action: {
          pay: 'Pay'
        },
        dialog_title: 'Click on the line you want to charge',
        notification: {
          successful_payment: 'Successful payment!'
        },
        status: {
          default: {
            label: 'Loading...',
            tooltip: 'Progressing...'
          },
          open: {
            label: 'Not payed',
            tooltip: 'Waiting for payment...'
          },
          paid: {
            label: 'Paid',
            tooltip: 'Paid successfully'
          }
        }
      },
      subscription: {
        fields: {
          contact: 'Contact Info',
          payment: 'Payment',
          billing: 'Billing Address'
        },
        message: {
          default: 'Something went wrong.',
          processing: 'Your subscription is processing.',
          requires_payment_method:
            'Your subscription was not successful. Please try again.',
          success: 'Payment succeeded!',
          canceled: 'Your subscription has been canceled. Please try again.',
          incomplete_address: 'Incomplete address.'
        }
      },
      tabs: {
        invoices: 'Invoices',
        methods: 'Cards',
        subscription: 'New Subscription'
      }
    },
    dashboard: {
      title: 'Overview'
    },
    login: {
      create_account: 'New user?',
      create_account_link: 'Create an account!',
      title: 'Sign in to %{name}',
      forgot_password: 'Forgot password?'
    },
    forgot_password: {
      title: 'Forgot password?',
      login: 'Return to sign in',
      send_recovery_email: 'Send recovery email',
      notification: {
        success: 'We sent an email for you. Please check your mailbox!',
        error: 'Something went wrong. Please try again later.'
      }
    },
    reset_password: {
      title: 'You can change your password',
      login: 'Do you remember your password? Sign in instead!',
      notification: {
        success: 'We sent an email for you. Please check your mailbox!',
        error: 'Something went wrong. Please try again later.'
      }
    },
    profile: {
      edit: {
        change_password: {
          fields: {
            changePassword: 'Your new password',
            changePasswordAgain: 'Your new password again',
            currentPassword: 'Your current password'
          },
          title: 'Change your password'
        },
        title: 'Edit Profile'
      },
      delete_account: {
        action: {
          delete: 'Delete Personal Account'
        },
        delete_confirmation:
          'To confirm deletion, type your full name in this field.',
        delete_title: 'Delete Your Account "%{name}"',
        notification: {
          delete_success: 'Your account has been successfully deleted.',
          delete_prevented:
            'Account deletion is temporarily disallowed. Please contact us for further assistance.'
        },
        title: 'Delete Account',
        subtitle:
          'Permanently delete your Reward Cloud account along with all associated data. This action is irreversible, so proceed with caution.',
        validation: {
          values_does_not_match: 'Values does not match.'
        }
      }
    },
    register: {
      action: {
        log_in: 'Already have an account?',
        log_in_link: 'Sign in',
        sign_up: 'Sign up'
      },
      agreement: 'I have read and agreed to the ',
      notification: {
        error: 'A server error has occured',
        success:
          'Account created successfully. Activation link has been sent to your email address!'
      },
      password_strength: {
        empty: 'There is no any suggestion',
        helper: 'Some tips about your password',
        label: 'Password strength'
      },
      privacy_policy: 'Privacy Policy',
      terms_of_service: 'Terms of Service',
      title: 'Register to %{name}',
      success_title: 'Almost there...'
    },
    activation: {
      message: {
        loading: `Verifying your account activation...`,
        unexpected_error: `Your activation link may be wrong or expired.`
      }
    }
  },
  resources: {
    access_codes: {
      fields: {
        accessCode: 'Access code',
        createdAt: 'Created at',
        usedAt: 'Used at'
      }
    },
    clusters: {
      name: 'Cluster |||| Clusters',
      fields: {
        name: 'Display name',
        codeName: 'Name',
        changeKubecostPass: 'Kubecost password',
        kubecostUrl: 'Kubecost url',
        kubecostUser: 'Kubecost username',
        clusterServer: 'Cluster server',
        clusterCertificateAuthorityData: 'Cluster certificate authority data',
        oidcIssuerUrl: 'Oidc issuer url',
        oidcClientID: 'Oidc client id',
        oidcClientSecret: 'Oidc client secret'
      },
      sections: {
        kubecost: 'Kubecost',
        cluster: 'Cluster'
      }
    },
    component_version_env_var_examples: {
      name: 'Environment Variable Example |||| Environment Variables Examples'
    },
    component_version_env_vars: {
      name: 'Environment Variable |||| Environment Variables',
      fields: {
        envVarValidations: 'Select validations',
        component_version_env_var_examples: 'Example values'
      },
      sections: {
        env_var_validations: 'Validations'
      }
    },
    component_version_resource_limits: {
      name: 'Resource Limit |||| Resource Limits',
      validation: {
        max: "Max value can't be smaller than min value",
        min: "Min value can't be larger than max value"
      }
    },
    component_versions: {
      fields: {
        component_resource_limits: 'Resource limits',
        component_version_env_vars: 'Environment variables'
      },
      name: 'Component Version |||| Component Versions'
    },
    components: {
      fields: {
        componentVersion: 'Version'
      },
      name: 'Component |||| Components',
      validation: {
        duplicate: 'Duplicate components.',
        missing_required:
          'Missing required component %{list}. |||| Missing required components %{list}.'
      }
    },
    env_vars: {
      name: 'Variable |||| Variables',
      label: {
        inherited_env_vars: 'Inherited from %{entity} ',
        no_inherited_env_vars:
          'No inherited environment variables from %{entity} '
      },
      validation: {
        missing_required:
          'Missing required variable %{list}. |||| Missing required variables %{list}.'
      }
    },
    environments: {
      name: 'Environment |||| Environments',
      empty:
        'This project do not have any environments yet. Click "Create" to start one.',
      action: {
        build_and_deploy: 'Build and deploy',
        export: 'Export Environment',
        import: 'Import to Environment',
        add_domain: 'Add domain',
        add_subdomain: 'Add subdomain for generated domain',
        show_component_settings: 'Show component settings'
      },
      fields: {
        accessBackendUrl: 'Backend Url',
        accessFrontendUrl: 'Frontend Url',
        accessHost: 'Host',
        accessPassword: 'Password',
        accessPort: 'Port',
        accessScheme: 'Scheme',
        accessUrl: 'Url',
        accessUsername: 'Username',
        componentVersion: 'Component',
        isAllowOutgoingEmails: 'Allow outgoing emails',
        isInitSampleData: 'Populate my database with demo data',
        isAllowHtaccessDevtoolsProtection: 'Devtools protection',
        isAllowSiteProtection: 'Enable site protection',
        isDisableRobots: 'Disable search engine indexing',
        state: 'Status',
        name: 'Environment name',
        targetRefName: 'Branch name',
        isDifferentTargetRefNameAndName: 'Use different name of the branch',
        url: 'Environment url',
        url_helper_text: "You can find out the environment's website here",
        copy_environment: {
          title: 'Copy data from environment',
          sourceId: 'Source environment',
          sourceIdPlaceholder: 'Select an environment...',
          disabled: "No active environments to copy from.",
          types: {
            database: 'Copy database',
            media: 'Copy media',
            resource: 'Copy resource settings'
          }
        },
        environmentCustomDomain: {
          hostname: 'Hostname',
          subdomain: 'Hostname'
        },
        environmentMagentoHostMapping: {
          mageRunCode: 'MAGE_RUN_CODE',
          mageRunType: 'MAGE_RUN_TYPE',
          hostname: 'Hostname'
        },
        storage_type: {
          ephemeral: 'Ephemeral',
          persistent: 'Persistent'
        }
      },
      helpers: {
        name_first_creation: `The environment's name should either match the name of the default branch of your repo or specifiy a branch name that fulfills this requirement.`,
        name: `The environment's name should either match the name of the chosen branch of your repo or specifiy a branch name that fulfills this requirement.`,
        infrastructure:
          'Choose a cloud provider for hosting and select a cluster to determine the geographical server grouping.',
        isAllowOutgoingEmails:
          'Allow your application to send emails via SMTP proxy.',
        isAllowHtaccessDevtoolsProtection: `Protect your application's devtools with a password.`,
        isAllowSiteProtection: 'Enable OAuth protection for the site.',
        isDisableRobots: `Prevent search engines from indexing the site by overriding robots.txt and setting 'noindex, nofollow' in the header.`,
        domains:
          'To personalize your site URL, include a custom domain like "example.com" alongside the default reward.cloud url. Define hostnames and related certificates for authorization.',
        environmentComponent: {
          title: 'What is this?',
          content:
            'As you customize your settings, instantly view estimated prices for CPU, memory, and storage, empowering you to make informed decisions about resource allocation. Enjoy real-time cost insights for efficient and budget-conscious configuration of your environment.'
        },
        resources:
          "Calculate the estimated costs of your environment. To customize each component's version and settings, simply click 'Show Components' to reveal advanced configuration options. Unleash the full potential of your environment with flexible and tailored component choices.",
        envVars: {
          title: 'What environment variables I should set?',
          content:
            'Well, it depends on your project type, so be sure to check the documentation of your system. Fear not, we have already set up the required ones for you. You just have to fill them out. \n If you already set up a variable on a higher level (e.g. in your project), it will be inherited here.'
        },
        storage_type: `<p><b>Ephemeral storage:</b> Non-persistent disk. If the container restarts all the data will be lost. You cannot change it later.</p>
        <p><b>Persistent storage:</b> This storage type should be used when you want to store data long term. You have the option to scale it up later but it cannot be scaled down.</p>
        </div>`
      },
      message: {
        no_environments_to_copy_from:
          'There is no other environment in the project.',
        no_environment_access: 'Environment access information is on the way.',
        source_environment_not_found: 'Source environment not found.',
      },
      notification: {
        failed_to_fetch_project: 'Failed to fetch the related project.',
        project_not_selected: 'Project is not selected.',
        created_but_imports_failed:
          'The element was created, but imports have failed. Please try again later.'
      },
      sections: {
        basic: 'Basic',
        name: 'Environment and branch name',
        infrastructure: 'Infrastructure settings',
        infrastructure_and_system: 'Infrastructure and system',
        database_and_media: 'Database and media',
        advanced: 'Advanced',
        customizations: 'Customizations',
        urls: 'Urls',
        domains: 'Custom Domains',
        magentoSettings: 'Magento Settings',
        magentoHostMappings: 'Host mappings',
        resources: 'Resource management and component settings',
        envVars: {
          title: 'Environment variables',
          subtitle: 'Set the environment variables for your environment.'
        },
        access: {
          sections: {
            application: {
              title: 'Application'
            },
            database: {
              subtitle: 'Access to phpMyAdmin',
              title: 'Database'
            },
            devTools: {
              title: 'Developer Tools'
            },
            rabbitmq: {
              subtitle: 'Access to RabbitMQ admin',
              title: 'RabbitMQ'
            },
            redis: {
              subtitle: 'Access to phpRedisAdmin',
              title: 'Redis'
            }
          },
          title: 'Access'
        },
        logs: {
          filters: 'Filters',
          application: 'Application',
          database: 'Database',
          message_queue: 'Message Queue',
          search_engine: 'Search Engine',
          redis: 'Redis',
          http_cache: 'HTTP Cache',
          build: 'Build',
          export_db: 'Export DB',
          export_media: 'Export Media',
          import_db: 'Import DB',
          import_media: 'Import Media',
          start_streaming: 'Start streaming',
          stop_streaming: 'Stop streaming',
          stream_not_running: 'Log stream is not running.',
          stream_running: 'Streaming logs...',
          scroll_to_top: 'Scroll to top',
          scroll_to_bottom: 'Scroll to bottom',
          load_more: 'Load more',
        }
      },
      tabs: {
        variables: 'Variables',
        costs: 'Estimated costs',
        details: 'Details',
        general: 'General',
        io: 'Import/Export',
        limits: 'Limits',
        resources: 'Resources',
        logs: 'Logs',
        advanced: 'Advanced'
      },
      validation: {
        name_already_taken: 'This name is already taken.',
        invalid_hostname: 'Invalid domain name',
        domain_already_taken: 'This domain is already taken.',
        invalid_certificate_key: 'Invalid key',
        invalid_certificate_data: 'Invalid certificate',
        invalid_dns_subdomain_name: `Value must contain only lowercase alphanumeric characters, "-" or ".", and start and end with an alphanumeric character.`
      },
    },
    env_var_types: {
      name: 'Env Var Type |||| Env Var Types'
    },
    env_var_validations: {
      name: 'Env Var Validation |||| Env Var Validations',
      fields: {
        test: 'Javascript RegExp test',
        js_regexp_result: 'Javascript RegExp'
      },
      validation: {
        invalid_regexp_format:
          'Invalid regular expression format. Must start with "/" and end with "/(flags)"',
        invalid_regexp: 'Invalid regular expression by JS standards.',
        test_failed: 'Test failed.'
      },
      sections: {
        project_type_version_env_vars: 'Used in Project Type Version Env Vars',
        component_version_env_vars: 'Used in Component Version Env Vars'
      },
      messages: {
        usage_notes: `The validation on this page is only for testing purposes. The actual validation is done on the server side, using the PCRE (Perl Compatible Regular Expressions) syntax.
        You should enter regexp in the same syntax. Follow the link for more information:`
      }
    },
    exported_datas: {
      name: 'Exported Data |||| Exported Datas',
      action: {
        export_database: 'Export database',
        export_media: 'Export media'
      },
      fields: {
        createdAt: 'Requested at'
      },
      exclude_sensitive_data: 'Exclude sensitive data',
      include_sensitive_data: 'Include sensitive data',
      without_sensitive_data: 'Without sensitive data',
      strip_database_label: 'Select whether to strip sensitive data',
      table_title: 'Previous exports'
    },
    imported_datas: {
      name: 'Imported Data |||| Imported Datas',
      fields: {
        createdAt: 'Requested at',
        dataTransfer: {
          cleanup: 'Clean up',
          fixUrls: 'Fix urls',
          awsSettings: {
            accessKeyId: 'AWS Access Key ID',
            bucket: 'Bucket name',
            endpointUrl: 'Endpoint url',
            key: 'File path in bucket',
            region: 'Region',
            secretAccessKey: 'AWS Secret Access Key'
          },
          source: 'Source url',
          type: 'Type',
          uploadProcess: 'Process type'
        },
        dataTransferFile: 'Upload file'
      },
      import_database: 'Import database',
      import_media: 'Import media'
    },
    organisations: {
      name: 'Organisation |||| Organisations',
      fields: {
        defaultUser: 'Owner',
        'defaultUser.fullname': 'Owner'
      },
      message: {
        delete_warning:
          'This means you will delete these <i>teams</i> and your projects as well.'
      },
      tabs: {
        general: 'General',
        teams: 'Teams',
        configuration: 'Configuration'
      },
      validation: {
        min_one_team: 'Minimum 1 Team have to be added in each Organisation',
        name_already_taken: 'This name is already taken.'
      },
      sections: {
        envVars: {
          title: 'Environment variables',
          subtitle: 'Set the environment variables for your organisation.'
        }
      },
      action: {
        add_team: 'Add team'
      }
    },
    products: {
      fields: {
        defaultPrice: {
          helper: 'IT IS MANDATORY IF YOU WANT TO CREATE INVOICES!!',
          label: 'Default price'
        },
        description: 'Description',
        isActive: 'Enable',
        name: {
          helper:
            'Currently, it can be ONLY: cpu, memory or storage. Any other name will become incomprehensible in the system',
          label: 'Product name'
        },
        paymentId: {
          helper:
            'Change it carefully - it have to corresponds w/ Stripe database',
          label: 'Stripe ID'
        },
        price: 'Price'
      },
      message: {
        no_provider: 'No provider'
      },
      name: 'Product |||| Products',
      tabs: {
        basic: 'Basic data',
        prices: 'Prices'
      }
    },
    project_type_version_env_var_examples: {
      name: 'Example |||| Examples'
    },
    project_type_version_env_vars: {
      name: 'Environment Variable |||| Environment Variables',
      fields: {
        envVarValidations: 'Select validations'
      },
      sections: {
        env_var_validations: 'Validations'
      }
    },
    project_type_versions: {
      fields: {
        project_type_version_env_vars: 'Environment variables',
        requiredComponent: 'Components',
        requiredComponentHelper:
          'Users will be required to provide this component to their projects and environments',
        componentVersions: 'Default component versions'
      },
      name: 'Project Type Version |||| Project Type Versions'
    },
    project_types: {
      name: 'Project Type |||| Project Types',
      sections: {
        versions: 'Versions'
      }
    },
    projects: {
      name: 'Project |||| Project',
      action: {
        deploy: 'Deploy',
        edit_git: 'Edit Repository',
        create_environment: 'Add environment'
      },
      fields: {
        'createdAt.before': 'Days Running',
        componentVersion: 'Default components',
        isInitProjectSkeleton: 'Initialize with starter project skeleton.',
        name: 'Project name',
        projectEnvVar: 'Environment variables',
        projectType: 'Project type',
        projectTypeVersion: 'Version',
        projectAccess: {
          projectAccessGit: {
            url: 'Git Repository URL:'
          },
          projectAccessRegistry: {
            url: 'Docker Registry URL:'
          }
        },
        state: 'Status',
        updatedAt: 'Last updated'
      },
      helpers: {
        resources: {
          title: 'How to choose the right amount of resources?',
          subtitle:
            'Choose the amount of vCPU, RAM and storage you want for your project.',
          content: `<p>Configure the resources for your instance to meet your specific needs by adjusting the vCPU, memory, and storage settings.</p>
          <ul>
              <li><strong>vCPU (Virtual CPUs):</strong> Adjust processing power using milliCPU (m) units, where 1000m equals 1 vCPU.</li>

              <li><strong>Memory:</strong> Tailor memory allocation in megabytes (Mi) to meet your application's demands.</li>

              <li><strong>Storage:</strong> Set storage capacity in gigabytes (Gi) based on your data requirements.</li>
          </ul>`
        },
        envVars: {
          title: 'What environment variables I should set?',
          content:
            'Well, it depends on your project type, so be sure to check the documentation of your system. Fear not, we have already set up the required ones for you. You just have to fill them out. \n If you already set up a variable on a higher level (e.g. in your team), it will be inherited here.'
        }
      },
      sections: {
        choose_template: {
          title: 'Choose a project template.'
        },
        components: {
          subtitle:
            'The selected components and their versions will serve as defaults in the environments. You will still have the option to modify them later.',
          title: 'Components'
        },
        create_method: {
          from_scratch: 'Create from scratch',
          from_template: 'Create from a template',
          title: 'How do you want to create your project?'
        },
        resources: 'Resource management',
        envVars: {
          title: 'Environment variables',
          subtitle: 'Set the environment variables for your project.'
        },
        deploy: {
          action: {
            go_to_project: 'Go to your project'
          },
          message: {
            environment_progress_tooltip:
              'You can access this link after the deployment is complete.',
            progress:
              'Your project and environments will be ready in a few minutes…',
            success: 'You are all set.'
          }
        },
        git: {
          title: 'Git repository'
        },
        identity: {
          title: 'Identity'
        },
        general: {
          title: 'General'
        },
        project_type: {
          title: 'Project type'
        },
        access: {
          title: 'Access'
        },
        usage_and_costs: {
          title: 'Usage and costs'
        }
      },
      tabs: {
        choose_template: 'Choose Template',
        deploy: 'Deploy',
        details: 'Details',
        details_template: 'Configure',
        general: 'General',
        resources: 'Resources',
        stack: 'Stack',
        variables: 'Variables',
        configuration: 'Configuration',
        import: 'Import',
        export: 'Export',
        io: 'Import/Export'
      },
      resources: {
        noFreeResource: 'No available free resources in the project.'
      },
      io: {
        noEnvironmentsToCopyFrom:
          'There is no other environment in the project.'
      },
      create: {
        projectNotSelected: 'Project is not selected.',
        failedToFetchProject: 'Failed to fetched the related project.'
      },
      validation: {
        nameAlreadyTaken: 'This name is already taken.'
      },
      notification: {
        delete_requested: 'Delete requested.'
      },
      message: {
        no_project_access: 'Project access information is on the way.',
        no_project_templates: 'There are no %{name} templates yet.',
        create_not_allowed: 'Your organisation is currently being created. Please wait for the process to finish.'
      }
    },
    providers: {
      name: 'Provider |||| Providers'
    },
    resource_types: {
      fields: {
        resourceTypeLimit: {
          componentMaxValue: 'Component max value',
          componentMinValue: 'Component min value',
          environmentMaxValue: 'Environment max value',
          environmentMinValue: 'Environment min value',
          projectMaxValue: 'Project max value',
          projectMinValue: 'Project min value'
        }
      },
      inputs: {
        cpu: {
          label: 'Virtual CPUs',
          tooltip: '1000m = 1 virtual CPU'
        },
        memory: {
          label: 'Memory'
        },
        node: {
          label: 'Replicas'
        },
        storage: {
          label: 'Storage'
        },
        storage_type: {
          label: 'Storage type'
        }
      },
      name: 'Resource type |||| Resource types',
      names: {
        cpu: 'CPU',
        memory: 'Memory',
        node: 'Replicas',
        storage: 'Storage'
      },
      validation: {
        lower_limit: 'Value must be equal or greater than %{value} %{unit}',
        out_of_range: 'Value out of range',
        upper_limit: 'Not enough resources'
      }
    },
    role_groups: {
      fields: {
        name: 'Name'
      }
    },
    states: {
      name: 'State |||| States',
      names: {
        1: 'Init requested',
        2: 'Initializing',
        3: 'Update requested',
        4: 'Updating',
        5: 'Progressing',
        6: 'Running',
        7: 'Stopping',
        8: 'Stopped',
        9: 'Delete requested',
        10: 'Deleting',
        11: 'Deleted',
        12: 'Server error',
        13: 'Client error',
        14: 'Ready',
        15: 'Build requested',
        16: 'Building',
        17: 'Deploy requested',
        18: 'Deploying',
        19: 'Import requested',
        20: 'Importing',
        21: 'Export requested',
        22: 'Exporting',
        23: 'Acknowledged',
        24: 'Pending',
        25: 'Creating',
        26: 'Created',
        27: 'Initialized',
        28: 'Progressed',
        29: 'Terminating',
        30: 'Import finished',
        31: 'Export finished',
        32: 'Deployment finished',
        33: 'Build finished',
        34: 'Build pending',
        35: 'Build creating',
        36: 'Build created',
        37: 'Build ready',
        38: 'Deployment pending',
        39: 'Deployment creating',
        40: 'Deployment created',
        41: 'Export pending',
        42: 'Export creating',
        43: 'Export created',
        44: 'Export ready',
        45: 'Import pending',
        46: 'Import creating',
        47: 'Import created',
        48: 'Import ready'
      }
    },
    teams: {
      fields: {
        teamEnvVar: 'Environment variables',
        defaultUser: 'Owner',
        'defaultUser.fullname': 'Owner'
      },
      name: 'Team |||| Teams',
      tabs: {
        general: 'General',
        configuration: 'Configuration',
        members: 'Members'
      },
      validation: {
        min_one_user: 'Minimum 1 User have to be added in each Team',
        name_already_taken: 'This name is already taken.'
      },
      action: {
        invite_user: 'Invite User'
      },
      sections: {
        envVars: {
          title: 'Environment variables',
          subtitle: 'Set the environment variables for your team.'
        },
        team_members: 'Team members',
        pending_invitations: 'Pending invitations',
        edit_team_member: 'Edit member "%{name}"'
      }
    },
    template_environments: {
      name: 'Environment Template |||| Environment Templates'
    },
    template_projects: {
      fields: {
        description: 'Template description'
      },
      name: 'Project Template |||| Project Templates'
    },
    users: {
      action: {
        administrate: 'Administrate'
      },
      fields: {
        changePassword: 'Change password',
        email: 'E-mail',
        isEnabled: 'Enable',
        roles: 'Permissions',
        username: 'Username',
        fullname: 'Full name',
        roleGroup: 'Role group',
        password: 'Password'
      },
      name: 'User |||| Users',
      tabs: {
        basic: 'Basic data',
        security: 'Security'
      },
      message: {
        userCannotBeDeletedIsAdminOfTeamWithProjects:
          'This user is an admin of a team with active projects.'
      }
    },
    invitations: {
      fields: {
        user: 'User',
        createdAt: 'Date sent',
        state: 'State',
        expiredAt: 'Expired at'
      },
      message: {
        empty: 'No pending invitations.',
        email_placeholder: 'Type email address',
        invitation_for: 'Invitation for %{user}'
      },
      states: {
        expired: 'Expired',
        pending: 'Pending',
        accepted: 'Accepted'
      },
      notification: {
        sent: 'Invitation sent.',
        resend_failed: "Couldn't send invitation.",
        resend_success: 'Invitation resent.'
      },
      validation: {
        already_in_team: 'User is already in the team'
      },
      helper: {
        user: `Select an existing user from the list or invite a new person by email.`
      }
    }
  },
  validation: {
    email: 'Must be a valid email',
    email_already_registered: 'This email is already registered here',
    letters_only: 'This field can only have letters (a-z, A-Z).',
    missing_required_option: 'Missing required %{resource} %{list}.',
    pass_does_not_match: 'The passwords does not match!',
    username:
      'Usernames can only have: letters (a-z, A-Z), numbers (0-9), dots (.) and hyphens (-)',
    username_already_registered: 'This username is already registered here',
    weak_password: 'Your password is too weak.'
  },
  amqp: {
    createEnvironment: 'Create Environment',
    deleteEnvironment: 'Delete Environment',
    createDeployment: 'Create Deployment',
    createBuild: 'Create Build',
    createBuildAndDeploy: 'Create Build and Deploy',
    createImport: 'Create Import',
    createExport: 'Create Export',
    streamEnvironmentLog: 'Stream Environment Log'
  }
};
